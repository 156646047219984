import moment from 'moment';

export const getTimeErrors = horarios => {
    let error = false;

    horarios.forEach(item => {
        // Validação do horário principal
        if (item.aberto) {
            if (!item.horario) {
                error = `O horário de ${item.diaSemana} deve estar no formato HH:mm - HH:mm`;
                return;
            }

            let horarios = item.horario.split(' - ');
            if (horarios.length !== 2) {
                error = `O horário de ${item.diaSemana} deve estar no formato HH:mm - HH:mm`;
            }

            let horarioValido = horarios.every(horario => moment(horario, 'HH:mm', true).isValid());
            if (!horarioValido) {
                error = `Os horários de ${item.diaSemana} devem estar no formato HH:mm e serem válidos`;
            }

            let horarioInicial = moment(horarios[0], 'HH:mm');
            let horarioFinal = moment(horarios[1], 'HH:mm');
            if (!horarioInicial.isBefore(horarioFinal)) {
                error = `O horário de ${item.diaSemana} deve estar entre 00:00 e 23:59`;
            }
        }

        // Validação do segundo horário, se ativo
        if (item.segundoHorarioAtivo) {
            if (!item.segundoHorario) {
                error = `O segundo horário de ${item.diaSemana} deve estar no formato HH:mm - HH:mm`;
                return;
            }

            let horarios = item.segundoHorario.split(' - ');
            if (horarios.length !== 2) {
                error = `O segundo horário de ${item.diaSemana} deve estar no formato HH:mm - HH:mm`;
            }

            let segundoHorarioValido = horarios.every(horario => moment(horario, 'HH:mm', true).isValid());
            if (!segundoHorarioValido) {
                error = `Os horários do segundo horário de ${item.diaSemana} devem estar no formato HH:mm e serem válidos`;
            }

            let horarioInicial = moment(horarios[0], 'HH:mm');
            let horarioFinal = moment(horarios[1], 'HH:mm');
            if (!horarioInicial.isBefore(horarioFinal)) {
                error = `O horário inicial do segundo horário de ${item.diaSemana} deve estar entre 00:00 e 23:59`;
            }
        }
    });

    return error;
};
